// AOS
AOS.init({
  duration: 1000
});

// SmoothScroll
var scroll = new SmoothScroll('[data-scroll]', {
  speed: 1500,
  speedAsDuration: true,
  header: '.navbar',
});

// Parallax
function parallax($parallaxElement) {
  var $parallaxElement = $($parallaxElement);
  var elementHeight = $parallaxElement.outerHeight();
  var scrollPos = $(window).scrollTop();
  var transformValue = scrollPos/40;
  var opacityValue =  1 - ( scrollPos / 2000);
  var backgroundValue =  100 - ( scrollPos / 50);
  var blurValue = Math.min(scrollPos / 20);
  if ( scrollPos < elementHeight ) {
    $parallaxElement.css({
      //'transform': 'translate3d(0, -' + transformValue + '%, 0)',
      //'opacity': opacityValue,
      'background' : 'linear-gradient(-90deg, transparent 79.1%, #FFF 0%), linear-gradient(180deg, transparent '+backgroundValue+'%, #FFF 0%), url("https://images.pexels.com/photos/373965/pexels-photo-373965.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") right center no-repeat'
    }); 
  } 
}
$(window).scroll(function() {
  parallax('.img-main .img');
});

/*
var elements = document.getElementsByClassName("view3d");
window.addEventListener("deviceorientation", function(e) {
  elements.forEach(element => {
    element.style.transform =
      "perspective(500px)" + 
      "rotateX(" + (-e.beta*.35 + 10) + "deg) " +
      "rotateY(" + (-e.gamma*.5) + "deg)";
  });
});
*/

// 3D Effect
document.addEventListener("DOMContentLoaded", function(event) {
  var rotX = 0, rotY = 0;
  if ( window.DeviceMotionEvent ) { 
    window.ondeviceorientation = function(event) {
      check = event.beta;
      beta = -(event.beta*.5 - 20);
      gamma = -(event.gamma*.5);
      if(check) {
        setTimeout(function(){
          normalizeData(beta, gamma);
        }, 50)
      }
    }  
  }
  function normalizeData(beta, gamma){
    rotX = beta;
    rotY = gamma;
    $('.view3d').css('transform', 'perspective(500px) rotateY(' + rotY +'deg) rotateX(' + rotX + 'deg)');
  }
});

// Blog Image Wrapper
$.fn.imgWrap = function() {
  var thisClass = this.attr('class');
  this.wrap('<div class="img '+ thisClass +'"></div>');
  this.removeClass();
}
$('.page-post img').each(function() {
  $(this).imgWrap();
});


(function($) {
  jQuery.fn.extend({
    getPseudo: function(pseudo, prop) {
      var props = window.getComputedStyle(
        $(this).get(0), pseudo
      ).getPropertyValue(prop);
      return String(props);
    },
    setPseudo: function(_pseudo, _prop, newprop) {
      var elem = $(this);
      var s = $("style");
      var p = elem.getPseudo(_pseudo, _prop);
      var r = p !== "" ? new RegExp(p) : false;
      var selector = $.map(elem, function(val, key) {
        return [val.tagName
                , val.id 
                  ? "#" + val.id : null
                , val.className ? "." + val.className : null]
      });
      var _setProp = "\n" + selector.join("")
        .toLowerCase()
        .concat(_pseudo)
        .concat("{")
        .concat(_prop + ":")
        .concat(newprop + "};");
      return ((!!r ? r.test($(s).text()) : r) 
              ? $(s).text(function(index, prop) {
                  return prop.replace(r, newprop)
                }) 
              : $(s).append(_setProp)
      );
    }
  })
})(jQuery);