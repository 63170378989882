function googleTranslateElementInit() {
  new google.translate.TranslateElement({
    pageLanguage: 'en',
    includedLanguages: 'en,es,fr,iw,pt',
    layout: google.translate.TranslateElement.InlineLayout.SIMPLE
  }, 'google_translate_element');
}

// RESTYLE THE DROPDOWN MENU
$('document').ready(function() {
  $('#google_translate_element').on("click", function() {
    // font family and color
    $("iframe").contents().find(".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *").css({
      'color': '#544F4B',
      'font-family': 'Open Sans, sans-serif',
      'width': '100%'
    });

    // menu's padding
    $("iframe").contents().find('.goog-te-menu2-item-selected').css('display', 'none');

    // menu's padding
    $("iframe").contents().find('.goog-te-menu2').css('padding', '0px');

    // the padding of the languages
    $("iframe").contents().find('.goog-te-menu2-item div').css('padding', '20px');

    // the width of the languages
    $("iframe").contents().find('.goog-te-menu2-item').css('width', '100%');
    $("iframe").contents().find('td').css('width', '100%');

    // hover effects
    $("iframe").contents().find(".goog-te-menu2-item div").hover(function() {
      $(this).css('background-color', '#4385F5').find('span.text').css('color', 'white');
    }, function() {
      $(this).css('background-color', 'white').find('span.text').css('color', '#544F4B');
    });

    // Google's default blue border
    $("iframe").contents().find('.goog-te-menu2').css('border', 'none');

    // iframe's box shadow
    $(".goog-te-menu-frame").css('box-shadow', '0 0 0 0');

    // iframe's size and position
    $(".goog-te-menu-frame").css({
      'position': 'absolute',
      'height': '234px',
      'width': '120px',
      'right': '120px !important',
    });
    // iframes's size
    $("iframe").contents().find('.goog-te-menu2').css({
      'height': 'auto',
      'width': '100%',
    });
  });
});

$('#google_translate_element').on("click", function() {
  new Popper($(this), $(".goog-te-menu-frame"), {
    placement: 'bottom-end'
  });
});
