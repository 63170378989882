// Main
$(document).ready( function () {
  var navbar = '.navbar',
      $navbar = $(navbar),
      toggler = $('.navbar-toggler'),
      collapseShow = 'navbar-collapse-show';
  toggler.on( "click", function() {
    if($navbar.hasClass(collapseShow)) {
      $navbar.removeClass(collapseShow);
    } else {
      $navbar.addClass(collapseShow);
    }
  });
  // Navbar scroll
  var scrollTop = 0;
  $(window).scroll(function() {
    scrollTop = $(window).scrollTop();
    $('.counter').html(scrollTop);
    if (scrollTop >= 45) {
      $navbar.addClass('navbar-scroll');
    } else if (scrollTop < 45) {
      $navbar.removeClass('navbar-scroll');
    }
  });
  // datatables init
  $('.datatable').DataTable();
});


// dataTables default config
$.extend( true, $.fn.dataTable.defaults, {
  "dom":  '<"d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between"' +
            '<"search mb-1"f><"lenght mb-1"l>'+
          '>' +
            '<"table-responsive my-1"tr>' +
          '<"d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between"' +
            '<"pagination mb-1"p><"small text-muted mb-1"i>'+
          '>',
  "language": {
    "url":  "//cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
  }
});
